import React, { useState, useEffect } from "react";
import { Menu, Container, Image, Dropdown, Label } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import { logout } from "../navActions";
import { useSelector } from "react-redux";

import firebase from "../../../app/config/firebase";
const firestore = firebase.firestore();

const version = "5.3.4";

export default function NavBar(props) {
    const [leadsunassigned, setLeadsunassigned] = useState([]);

    const { currentUserProfile } = useSelector((state) => state.profile);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("leads")
            .where("status", "==", "Unassigned")
            .onSnapshot((snapshot) => {
                setLeadsunassigned(snapshot.docs);
            });

        return () => unsubscribe();
    }, []);

    function handleSignOut() {
        logout();
        window.location.replace("/");
    }

    if (!currentUserProfile) return "Loading...";

    return (
        <div>
            <Menu size="mini" style={{ marginBottom: 20 }}>
                <Container>
                    <Menu.Item>
                        <img className="ui fluid image" src="/assets/logo2.png" alt="logo" />
                    </Menu.Item>

                    {currentUserProfile && currentUserProfile.isSuper && <Menu.Item as={NavLink} to="/dashboard/landing" name="Dashboard" />}

                    {currentUserProfile && !currentUserProfile.isExternal && <Menu.Item as={NavLink} to="/clients/landing" name="Clients" />}
                    <Menu.Item as={NavLink} to="/leads/landing" name="Assigned Leads" />
                    {currentUserProfile && !currentUserProfile.isExternal && (
                        <Menu.Item as={NavLink} to="/leads/unassigned">
                            Unassigned Leads
                            {leadsunassigned && leadsunassigned.length > 0 && <Label color="red">{leadsunassigned.length}</Label>}
                        </Menu.Item>
                    )}
                    {currentUserProfile && !currentUserProfile.isExternal && <Menu.Item as={NavLink} to="/reminders" name="Reminders" />}
                    {currentUserProfile && !currentUserProfile.isExternal && <Menu.Item as={NavLink} to="/leads/export" name="Leads Export" />}
                    {currentUserProfile && !currentUserProfile.isExternal && <Menu.Item as="a" target="_blank" href="https://sites.google.com/view/igointranet/" name="Updates" />}
                    {/* <Menu.Item as={NavLink} to="/quotes" name="Quotes" /> */}
                    {currentUserProfile && currentUserProfile.isSuper && (
                        <Menu.Item as={NavLink} to="/admin/landing">
                            ADMIN
                        </Menu.Item>
                    )}

                    <Menu.Item position="right">
                        <Image avatar spaced="right" src={currentUserProfile?.photoURL || "/assets/user.png"} />
                        <Dropdown
                            pointing="top left"
                            text={
                                currentUserProfile && currentUserProfile.displayName && currentUserProfile.mailtrap && currentUserProfile.mailtrap === true
                                    ? currentUserProfile.displayName + " (Mailtrap) (" + version + ")"
                                    : currentUserProfile.displayName + " (" + version + ")"
                            }
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to={`/admin/user/${currentUserProfile?.id}`} text="Settings" icon="settings" />
                                <Dropdown.Item onClick={handleSignOut} text="Sign Out" icon="power" />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                </Container>
            </Menu>
        </div>
    );
}
