import React, { useEffect, useState } from "react";
import { Modal, Button, Grid, Divider, Header, Table, Confirm } from "semantic-ui-react";
import { closeModal } from "../../app/common/modals/modalReducer";
import { useDispatch } from "react-redux";
import { getLeadEmail, addScheduleEmail, deleteScheduleEmail } from "../leads/leadActions";
import format from "date-fns/format";
import { Formik } from "formik";
import MySelectInput from "../../app/common/form/MySelectInput";
import MyDateInput from "../../app/common/form/MyDateInput";
import MyCheckboxInput from "../../app/common/form/MyCheckboxInput";
import MyTinyMCE from "../../app/common/form/MyTinyMCE";
import firebase from "../../app/config/firebase";
const firestore = firebase.firestore();

export default function LeadScheduledEmailModal(props) {
    const dispatch = useDispatch();

    const actionType = [
        { key: "", text: "Choose an Action", value: "" },
        { key: "Reminder", text: "Reminder", value: "Reminder" },
        { key: "Travel oute of date range", text: "Travel oute of date range", value: "Travel oute of date range" },
        { key: "Bon Voyage Email", text: "Bon Voyage Email", value: "Bon Voyage Email" },
        { key: "Welcome Home Email", text: "Welcome Home Email", value: "Welcome Home Email" },
        { key: "Happy Birthday", text: "Happy Birthday", value: "Happy Birthday" },
    ];

    const [email_scheduled, setEmail_scheduled] = useState([]);

    useEffect(() => {
        const unsubscribe = firestore
            .collection("email_scheduled")
            .where("leadId", "==", props.lead.id)
            .onSnapshot((snapshot) => {
                let list = [];

                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        let appObj = { ...doc.data(), id: doc.id };
                        list.push(appObj);
                    }
                });

                list.sort((a, b) => (a.action_dt < b.action_dt ? 1 : -1));

                setEmail_scheduled(list);
            });

        return () => unsubscribe();
    }, []);

    const [deleteID, setDeleteID] = useState("");

    function openD(es) {
        setDeleteID(es.id);
    }
    function close() {
        setDeleteID("");
    }

    async function deleteSchedEmail(deleteID) {
        await deleteScheduleEmail(deleteID);

        setDeleteID("");
    }

    return (
        <>
            <Modal size="large" open={true} onClose={() => dispatch(closeModal())}>
                <Modal.Header>Reminders / Scheduled Emails</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Formik
                            initialValues={{ action_type: "", action_dt: "", send_client_email: false, message: "" }}
                            validate={(values) => {
                                const error = {};
                                if (!values.action_type) {
                                    error.action_type = "Action Type is required";
                                }
                                if (!values.action_dt) {
                                    error.action_dt = "Action Date is required";
                                }
                                return error;
                            }}
                            onSubmit={async (values, { setSubmitting }) => {
                                values.leadId = props.lead.id;

                                //client_email
                                values.client_email = props.lead.client_email;

                                //consultant_email
                                values.consultant_email = props.profile.email;

                                if (props.profile.mailtrap && props.profile.mailtrap === true) {
                                    values.mailtrap = true;
                                }

                                //Map Shortcodes
                                values.message = values.message.replace("[firstname]", props.lead.client_name);
                                values.message = values.message.replace("[email]", props.lead.client_email);

                                await addScheduleEmail(values);

                                setSubmitting(false);
                            }}
                        >
                            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                                <form onSubmit={handleSubmit} className="ui form">
                                    <Grid>
                                        <Grid.Row verticalAlign="middle">
                                            <Grid.Column width={6}>
                                                <MySelectInput
                                                    label="Action"
                                                    name="action_type"
                                                    options={actionType}
                                                    placeholder="Choose an Action"
                                                    onChange={(field, value) => {
                                                        let message = "";

                                                        if (value.value === "Bon Voyage Email") {
                                                            message = `
                                                                <table width="100%">
                                                                <tr>
                                                                <td>
                                                                    <table width="600" align="center">
                                                                    <tr>
                                                                        <td colspan="2">
                                                                        <img
                                                                            src="https://leads.igotravel.co.za/assets/emails/bon_voyage/header.jpg"
                                                                            alt=""
                                                                        />
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                        <table width="100%" style="padding: 20px">
                                                                            <tr>
                                                                            <td align="center" colspan="2">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/alert.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td style="padding-top: 50px" width="120">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/1.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                padding-top: 50px;
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                "
                                                                            >
                                                                                Check your travel documents; make sure you have copies of your
                                                                                passport, unabridged brith certificates if travelling with
                                                                                minors, travel itinerary & e-tickets.
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td style="padding-top: 50px" width="120">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/2.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                padding-top: 50px;
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                "
                                                                            >
                                                                                Travel insurance is crucial, take out cover for the start to
                                                                                the finish of your holiday, for total piece of mind.
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td style="padding-top: 50px" width="120">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/3.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                padding-top: 50px;
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                "
                                                                            >
                                                                                Check-in online. Check-in opens 72-24 hours prior to
                                                                                departure, depending on the airline.
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td style="padding-top: 50px" width="120">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/4.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                padding-top: 50px;
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                "
                                                                            >
                                                                                Please allow adequate time to arrive at the airport for your
                                                                                departure, (2 hours prior to domestic & 3 hours prior to
                                                                                international travel).
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td style="padding-top: 50px" width="120">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/5.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                padding-top: 50px;
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                "
                                                                            >
                                                                                Make sure you have downloaded our IGO TRAVEL app to have an
                                                                                electronic version of your documents on your phone.
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                colspan="2"
                                                                                style="
                                                                                font-family: Arial, Helvetica;
                                                                                padding-top: 40px;
                                                                                padding-bottom: 10px;
                                                                                "
                                                                                align="center"
                                                                            >
                                                                                Click here to download our app:
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td colspan="2" align="center">
                                                                                <a href="#" target="_blank"
                                                                                ><img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/bon_voyage/download_button.jpg"
                                                                                    alt=""
                                                                                /></a>
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td colspan="2" align="center" style="padding-top: 30px">
                                                                                <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/bon_voyage/sign_off.jpg"
                                                                                alt=""
                                                                                />
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                text-align: center;
                                                                                font-weight: 600;
                                                                                padding-top: 40px;
                                                                                "
                                                                                colspan="2"
                                                                            >
                                                                                Don't forget to follow us on Facebook
                                                                                <a
                                                                                href="https://facebook.com/iGoTravel"
                                                                                target="_blank"
                                                                                style="text-decoration: none; color: black"
                                                                                ><img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/bon_voyage/facebook.jpg"
                                                                                    alt=""
                                                                                />@igotravel</a
                                                                                >
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="
                                                                                font-family: Arial, Helvetica, sans-serif;
                                                                                text-align: center;
                                                                                font-weight: 600;
                                                                                padding-top: 10px;
                                                                                padding-bottom: 40px;
                                                                                "
                                                                                colspan="2"
                                                                            >
                                                                                and on Instagram
                                                                                <a
                                                                                href="https://www.instagram.com/igotravelza/"
                                                                                target="_blank"
                                                                                style="text-decoration: none; color: black"
                                                                                ><img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/bon_voyage/instagram.jpg"
                                                                                    alt=""
                                                                                />@igotravelza
                                                                                </a>
                                                                            </td>
                                                                            </tr>
                                                                        </table>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan="2">
                                                                        <img
                                                                            src="https://leads.igotravel.co.za/assets/emails/bon_voyage/footer.jpg"
                                                                            alt=""
                                                                        />
                                                                        </td>
                                                                    </tr>
                                                                    </table>
                                                                </td>
                                                                </tr>
                                                            </table>                                                          
                                                            `;
                                                        } else if (value.value === "Welcome Home Email") {
                                                            message = `
                                                                <table width="100%">
                                                                    <tr>
                                                                    <td>
                                                                        <table width="600" align="center">
                                                                        <tr>
                                                                            <td>
                                                                            <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/welcome_home/header.jpg"
                                                                                alt=""
                                                                            />
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>
                                                                            <table width="100%" style="padding: 20px">
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    text-align: center;
                                                                                    padding-top: 30px;
                                                                                    padding-bottom: 10px;
                                                                                    "
                                                                                >
                                                                                    <img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/welcome_home/a_special_note.jpg"
                                                                                    alt=""
                                                                                    />
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    font-family: Arial, Helvetica, sans-serif;
                                                                                    letter-spacing: 1px;
                                                                                    text-align: center;
                                                                                    padding-top: 20px;
                                                                                    padding-left: 20px;
                                                                                    padding-right: 20px;
                                                                                    padding-bottom: 20px;
                                                                                    "
                                                                                >
                                                                                    A special note from me to say thank you for booking with us. I
                                                                                    trust it was everything you planned and hoped for!
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    font-family: Arial, Helvetica, sans-serif;
                                                                                    letter-spacing: 1px;
                                                                                    text-align: center;
                                                                                    padding-top: 20px;
                                                                                    padding-left: 20px;
                                                                                    padding-right: 20px;
                                                                                    padding-bottom: 20px;
                                                                                    "
                                                                                >
                                                                                    Please send us pictures of your holiday. We would love to
                                                                                    share your experience with our community!
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    font-family: Arial, Helvetica, sans-serif;
                                                                                    letter-spacing: 1px;
                                                                                    text-align: center;
                                                                                    padding-top: 20px;
                                                                                    padding-left: 20px;
                                                                                    padding-right: 20px;
                                                                                    padding-bottom: 20px;
                                                                                    "
                                                                                >
                                                                                    If you are happy with our service and loved dealing with us,
                                                                                    please let your friends know and refer them to us.
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td style="padding-top: 40px">
                                                                                    <img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/welcome_home/thank_you.jpg"
                                                                                    alt=""
                                                                                    />
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td>
                                                                                    <img
                                                                                    src="https://leads.igotravel.co.za/assets/emails/welcome_home/write_a_review.jpg"
                                                                                    alt=""
                                                                                    />
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    font-family: Arial, Helvetica, sans-serif;
                                                                                    text-align: center;
                                                                                    font-weight: 600;
                                                                                    padding-top: 40px;
                                                                                    "
                                                                                >
                                                                                    Don't forget to follow us on Facebook
                                                                                    <a
                                                                                    href="https://facebook.com/iGoTravel"
                                                                                    target="_blank"
                                                                                    style="text-decoration: none; color: black"
                                                                                    ><img
                                                                                        src="https://leads.igotravel.co.za/assets/emails/welcome_home/facebook.jpg"
                                                                                        alt=""
                                                                                    />@igotravel</a
                                                                                    >
                                                                                </td>
                                                                                </tr>
                                                                                <tr>
                                                                                <td
                                                                                    style="
                                                                                    font-family: Arial, Helvetica, sans-serif;
                                                                                    text-align: center;
                                                                                    font-weight: 600;
                                                                                    padding-top: 10px;
                                                                                    padding-bottom: 40px;
                                                                                    "
                                                                                >
                                                                                    and on Instagram
                                                                                    <a
                                                                                    href="https://www.instagram.com/igotravelza/"
                                                                                    target="_blank"
                                                                                    style="text-decoration: none; color: black"
                                                                                    ><img
                                                                                        src="https://leads.igotravel.co.za/assets/emails/welcome_home/instagram.jpg"
                                                                                        alt=""
                                                                                    />@igotravelza
                                                                                    </a>
                                                                                </td>
                                                                                </tr>
                                                                            </table>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="2">
                                                                            <img
                                                                                src="https://leads.igotravel.co.za/assets/emails/welcome_home/footer.jpg"
                                                                                alt=""
                                                                            />
                                                                            </td>
                                                                        </tr>
                                                                        </table>
                                                                    </td>
                                                                    </tr>
                                                                </table>                                                                                                                                    
                                                            `;
                                                        }

                                                        setFieldValue("message", message);
                                                        setFieldValue("action_type", value.value);
                                                    }}
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={6}>
                                                <MyDateInput label="Action Date" name="action_dt" dateFormat="yyyy-MM-dd" showYearDropdown={true} showMonthDropdown={true} dropdownMode="select" />
                                            </Grid.Column>
                                            <Grid.Column width={4}>
                                                <MyCheckboxInput label="" labelcb="Send Client Email" name="send_client_email" type="checkbox" />
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <p style={{ color: "red" }}>
                                                    <em>Remember to tick Send Client Email if this email needs to go to the client</em>
                                                </p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column>
                                                <MyTinyMCE label="Message*" name="message" type="text" onEditorChange={(value) => setFieldValue("message", value)} />
                                                <p>Shortcodes: [firstname], [email]</p>
                                            </Grid.Column>
                                        </Grid.Row>

                                        <Grid.Row columns={1}>
                                            <Grid.Column textAlign="center">
                                                <Button type="submit" color="orange" disabled={isSubmitting} loading={isSubmitting}>
                                                    Add
                                                </Button>
                                                <Button disabled={isSubmitting} onClick={() => dispatch(closeModal())}>
                                                    Close
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </form>
                            )}
                        </Formik>

                        <Divider />

                        <Header>Reminders / Scheduled Email Log</Header>

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Action Type</Table.HeaderCell>
                                    <Table.HeaderCell>Action Date</Table.HeaderCell>
                                    <Table.HeaderCell>Email Client</Table.HeaderCell>
                                    <Table.HeaderCell>Status</Table.HeaderCell>
                                    <Table.HeaderCell>Actions</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>

                            <Table.Body>
                                {email_scheduled &&
                                    email_scheduled.length > 0 &&
                                    email_scheduled.map((es) => (
                                        <Table.Row key={es.id}>
                                            <Table.Cell>{es.action_type}</Table.Cell>
                                            <Table.Cell>{format(es.action_dt.toDate(), "dd MMMM yyyy")}</Table.Cell>
                                            <Table.Cell>{es.send_client_email && es.send_client_email === true ? "Yes" : "No"}</Table.Cell>
                                            <Table.Cell>{es.status}</Table.Cell>
                                            <Table.Cell>
                                                {es.status === "Pending" && (
                                                    <Button color="red" onClick={() => openD(es)}>
                                                        Delete
                                                    </Button>
                                                )}
                                            </Table.Cell>
                                        </Table.Row>
                                    ))}
                            </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
            </Modal>

            <Confirm
                header="You are about to delete this scheduled email"
                content="This cannot be undone, are you sure?"
                size="tiny"
                open={deleteID !== ""}
                onCancel={close}
                onConfirm={() => deleteSchedEmail(deleteID)}
                confirmButton="Yes"
            />
        </>
    );
}
